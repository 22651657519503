import { I18N } from 'aurelia-i18n';
import './net-summary.scss';
import { bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { autoinject } from 'aurelia-framework';

type Dimensions = {
  AreaLargeFish?: number;
  AreaSmallFish?: number;
  VolumeLargeFish?: number;
  VolumeSmallFish?: number;
} & Models.NetDimension;

@autoinject
export class NetSummary {
  @bindable private dimensions: Dimensions = {} as Dimensions;
  @bindable designType: Models.DesignType;

  areaHtml = '';
  volumeHtml = '';

  constructor(private i18n: I18N) {}

  private round(value: number) {
    if (typeof value === 'number') {
      return Number.parseFloat(value.toFixed(2));
    }
    return 0;
  }

  private addCombiNetAreaData(areaData: string[], areaDimensions: Models.NetDimensionAreaData) {
    const areaLargeFish = this.round(
      areaDimensions.AreaWallCombiTopLargeFish + areaDimensions.AreaWallCombiTopLargeFishExcess
    );
    const areaSmallFish = this.round(
      areaDimensions.AreaWallCombiBottomSmallFish + areaDimensions.AreaWallCombiBottomSmallFishExcess
    );

    areaData.push('');
    areaData.push(`${this.i18n.tr('netsummary.area.AreaLargeFish')}: ${areaLargeFish}`);
    areaData.push(`${this.i18n.tr('netsummary.area.AreaSmallFish')}: ${areaSmallFish}`);

    if (!this.fieldsRight.find((fr) => fr.value === 'AreaLargeFish')) {
      this.fieldsRight.splice(
        2,
        0,
        {
          label: 'netsummary.area.AreaLargeFish',
          value: 'AreaLargeFish',
          unit: 'general.m2_short',
          forCombinet: true,
        },
        {
          label: 'netsummary.area.AreaSmallFish',
          value: 'AreaSmallFish',
          unit: 'general.m2_short',
          forCombinet: true,
        }
      );
    }

    this.dimensions.AreaLargeFish = areaLargeFish;
    this.dimensions.AreaSmallFish = areaSmallFish;
  }

  private addCombiNetVolumeData(volumeData: string[], volumeDimensions: Models.NetDimensionVolumeData) {
    const volumeLargeFish = this.round(volumeDimensions.VolumeCombiLargeFish);
    const volumeSmallFish = this.round(volumeDimensions.VolumeCombiSmallFish);

    volumeData.push('');
    volumeData.push(`${this.i18n.tr('netsummary.volume.VolumeLargeFish')}: ${volumeLargeFish}`);
    volumeData.push(`${this.i18n.tr('netsummary.volume.VolumeSmallFish')}: ${volumeSmallFish}`);
    volumeData.push('');

    if (!this.fieldsRight.find((fr) => fr.value === 'VolumeLargeFish')) {
      this.fieldsRight.splice(
        1,
        0,
        {
          label: 'netsummary.volume.VolumeLargeFish',
          value: 'VolumeLargeFish',
          unit: 'general.cubic_meter_short',
          forCombinet: true,
        },
        {
          label: 'netsummary.volume.VolumeSmallFish',
          value: 'VolumeSmallFish',
          unit: 'general.cubic_meter_short',
          forCombinet: true,
        }
      );
    }

    this.dimensions.VolumeLargeFish = volumeLargeFish;
    this.dimensions.VolumeSmallFish = volumeSmallFish;
  }

  dimensionsChanged() {
    if (this.dimensions?.AreaData) {
      const ad = this.dimensions.AreaData;
      const areaData = [];
      areaData.push(`${this.i18n.tr('netsummary.area.AreaWall')}: ${ad.AreaWall + ad.AreaWallExcess}`);
      areaData.push(`${this.i18n.tr('netsummary.area.JumpingNet')}: ${ad.AreaJumpingNet + ad.AreaJumpingNetExcess}`);
      if (ad.AreaOuterBottom) {
        areaData.push(
          `${this.i18n.tr('netsummary.area.AreaOuterBottom')}: ${ad.AreaOuterBottom + ad.AreaOuterBottomExcess}`
        );
      }
      if (this.designType?.DesignCode === 'MNCN-F') {
        areaData.push(
          `${this.i18n.tr('netsummary.area.AreaSides')}: ${
            ad.AreaWall +
            ad.AreaWallExcess +
            ad.AreaJumpingNet +
            ad.AreaJumpingNetExcess +
            ad.AreaOuterBottom +
            ad.AreaOuterBottomExcess
          }`
        );
      } else {
        areaData.push(
          `${this.i18n.tr('netsummary.area.AreaSides')}: ${
            ad.AreaWall + ad.AreaWallExcess + ad.AreaJumpingNet + ad.AreaJumpingNetExcess
          }`
        );
      }

      if (this.dimensions.IsCombiNet) {
        this.addCombiNetAreaData(areaData, ad);
      }

      areaData.push('');
      areaData.push(
        `${this.i18n.tr('netsummary.area.AreaInnerBottom')}: ${ad.AreaInnerBottom + ad.AreaInnerBottomExcess}`
      );
      areaData.push(
        `${this.i18n.tr('netsummary.area.AreaReinforcementBottom')}: ${
          ad.AreaReinforcementBottom + ad.AreaReinforcementBottomExcess
        }`
      );
      areaData.push('');
      areaData.push(
        `${this.i18n.tr('netsummary.area.AreaReinforcementMainRope')}: ${
          ad.AreaReinforcementMainRope + ad.AreaReinforcementMainRopeExcess
        }`
      );
      areaData.push(
        `${this.i18n.tr('netsummary.area.AreaReinforcementBottomRope')}: ${
          ad.AreaReinforcementBottomRope + ad.AreaReinforcementBottomRopeExcess
        }`
      );
      areaData.push(
        `${this.i18n.tr('netsummary.area.AreaReinforcementTotal')}: ${
          ad.AreaReinforcementTotal + ad.AreaReinforcementTotalExcess
        }`
      );

      areaData.push('');
      areaData.push(`${this.i18n.tr('general.rinsArea')}: ${ad.AreaWashable}`);
      areaData.push('');
      areaData.push(`${this.i18n.tr('netsummary.area.AreaTotal')}: ${ad.AreaTotal + ad.AreaTotalExcess}`);
      this.areaHtml = null;

      setTimeout(() => {
        this.areaHtml = areaData.join('<br/>');
      }, 100);
    } else {
      this.areaHtml = null;
    }

    if (this.dimensions?.VolumeData) {
      const vd = this.dimensions.VolumeData;

      const volumeData = [];
      volumeData.push(`${this.i18n.tr('netsummary.volume.VolumeToBottomRope')}: ${vd.VolumeToBottomRope}`);
      volumeData.push(`${this.i18n.tr('netsummary.volume.VolumeInnerBottom')}: ${vd.VolumeInnerBottom}`);

      if (this.dimensions.IsCombiNet) {
        this.addCombiNetVolumeData(volumeData, vd);
      }

      if (vd.VolumeOuterBottom) {
        volumeData.push(`${this.i18n.tr('netsummary.volume.VolumeOuterBottom')}: ${vd.VolumeOuterBottom}`);
      }

      volumeData.push('');
      volumeData.push(`${this.i18n.tr('netsummary.volume.VolumeTotal')}: ${vd.VolumeTotal}`);

      this.volumeHtml = null;

      setTimeout(() => {
        this.volumeHtml = volumeData.join('<br/>');
      }, 100);
    } else {
      this.volumeHtml = null;
    }
  }

  // show_for is used to only show for specified designtype codes
  // hide_for is used to only hide for specified designtype codes
  // class is used to add a special class to the item, e.g. for spacing/highlighting
  fieldsLeft: Array<any> = [
    {
      label: 'netsummary.SideCount',
      value: 'SideCount',
      unit: 'general.qty',
    },
    {
      label: 'netsummary.SideRopes',
      value: 'SideRopes',
      unit: 'general.qty',
    },
    {
      label: 'netsummary.CircumferenceProduction',
      value: 'CircumferenceProduction',
      unit: 'general.meter_short',
    },
    {
      label: 'netdimension.ExcessCircumferencePercentage',
      value: 'ExcessCircumferencePercentage',
      unit: 'general.percentage_short',
    },
    {
      label: 'netsummary.BottomRopeCircumference',
      value: 'BottomRopeCircumference',
      unit: 'general.meter_short',
      show_for: ['MNCN-F', 'MNHC-ST', 'MNCL-C', 'MNCL-ST', 'MNHC-CW'],
    },
    {
      label: 'netsummary.SquareLength',
      value: 'Length',
      unit: 'general.meter_short',
      show_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.SquareWidth',
      value: 'Width',
      unit: 'general.meter_short',
      show_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.SquareSlopeCornerLength',
      value: 'SlopeCornerLength',
      unit: 'general.meter_short',
      show_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.BottomSquareLength',
      value: 'BottomLength',
      unit: 'general.meter_short',
      show_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.BottomSquareWidth',
      value: 'BottomWidth',
      unit: 'general.meter_short',
      show_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.DepthVertical',
    },
    {
      label: 'netsummary.HeightJumpNet',
      value: 'HeightJumpNet',
      unit: 'general.meter_short',
    },
    {
      label: 'netsummary.DepthBottom',
      value: 'DepthBottom',
      unit: 'general.meter_short',
    },
    {
      label: 'netsummary.DepthBottomCenter',
      value: 'DepthBottomCenter',
      unit: 'general.meter_short',
    },
    {
      label: 'netsummary.DepthBottomCenterExtraBottom',
      value: 'DepthBottomCenterExtraBottom',
      unit: 'general.meter_short',
      show_for: ['MNCN-F', 'MNHC-CW'],
    },
    {
      label: 'netsummary.TotalDepth',
      value: 'TotalDepth',
      unit: 'general.meter_short',
    },
    {
      label: 'netsummary.SidesDistanceBetween',
      labelToolTip: 'netsummary.SidesDistanceBetweenHelpText',
    },
    {
      label: 'netsummary.DistanceBetweenLiftingRopes',
      value: 'DistanceLiftingRopes',
      unit: 'general.meter_short',
    },
    {
      label: 'netsummary.DistanceBetweenSideRopes',
      value: 'DistanceBetweenSideRopes',
      unit: 'general.meter_short',
    },
  ];

  fieldsRight: Array<any> = [
    {
      label: 'netsummary.Volume',
      value: 'Volume',
      unit: 'general.cubic_meter_short',
      volumeToolTip: true,
    },
    {
      label: 'netsummary.AreaNetting',
      value: 'AreaNetting',
      unit: 'general.m2_short',
      areaToolTip: true,
    },
    {
      label: 'netsummary.Weight',
      value: 'Weight',
      unit: 'general.kilogram_short',
    },
    {
      label: 'netsummary.SlopeAngle',
      value: 'SlopeAngle',
      unit: 'general.angle_symbol',
      class: 'mt-4',
    },
    {
      label: 'netsummary.SlopeAngleSide',
      value: 'SlopeAngleSide',
      unit: 'general.angle_symbol',
      hide_for: ['MNCL', 'MNCL-C', 'MNCL-ST'],
    },
    {
      label: 'netsummary.DepthSides',
      hide_for: ['MNCL', 'MNCL-C', 'MNCL-ST', 'MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.LengthSideWaterLineBottomRope',
      value: 'LengthSideWaterLineBottomRope',
      unit: 'general.meter_short',
      hide_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC', 'MNCL', 'MNCL-C', 'MNCL-ST'],
    },
    {
      label: 'netsummary.RadiusMiddleSideToCenterBottomCone',
      value: 'RadiusMiddleSideToCenterBottomCone',
      unit: 'general.meter_short',
      hide_for: ['MNCL', 'MNCL-C', 'MNCL-ST', 'MNCN-F', 'MNHC-CW', 'MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.LengthNettingCrossRopeOuterBottom',
      value: 'LengthNettingCrossRopeOuterBottomMiddleSideToCenterBottom',
      unit: 'general.meter_short',
      show_for: ['MNCN-F', 'MNHC-CW'],
    },
    {
      label: 'netsummary.TotalSideLength',
      value: 'TotalLengthSideToBottom',
      unit: 'general.meter_short',
      show_for: ['MNCN-F', 'MNHC-CW'],
    },
    {
      label: 'netsummary.BottomLengthCrossRopes',
      show_for: ['MNCL', 'MNCL-C', 'MNCL-ST'],
    },
    {
      label: 'netsummary.InnerBottomLengthCrossRopes',
      hide_for: ['MNCL', 'MNCL-C', 'MNCL-ST'],
    },
    {
      label: 'netsummary.LengthNettingCrossRopeInnerBottomCornerToCenter',
      value: 'LengthNettingCrossRopeInnerBottomCornerToCenter',
      unit: 'general.meter_short',
    },
    {
      label: 'netsummary.LengthNettingCrossRopeInnerBottomCornerToMidSide',
      value: 'LengthNettingCrossRopeInnerBottomCornerToMidSide',
      unit: 'general.meter_short',
      hide_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.LengthNettingCrossRopeInnerBottomCornerToMidSideSquareShort',
      value: 'NettingBottomLength',
      unit: 'general.meter_short',
      show_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
    {
      label: 'netsummary.LengthNettingCrossRopeInnerBottomCornerToMidSideSquareLong',
      value: 'NettingBottomWidth',
      unit: 'general.meter_short',
      show_for: ['MNSQ', 'MNSQ-CC', 'MNR', 'MNR-CC'],
    },
  ];
}
