import './net-illustration.scss';
import { bindable, LogManager } from 'aurelia-framework';
import { Models } from 'models/core';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger('net-illustration');

export class NetIllustration {
  @bindable dimensions: any = {};
  @bindable designType: Models.DesignType;
  private viewMode: string;
  private infoActive = true;
  private threejsPreview: HTMLElement;
  private drawingSideEl: HTMLElement;
  private drawingTopEl: HTMLElement;
  private drawingTopContainerEl: HTMLElement;
  private drawingSideContainerEl: HTMLElement;

  private colorLiftingRopes = '#00AF14';
  private colorSideRopes = '#FF5959';
  private colorDefault = 'black';
  private colorFaded = '#C4C4C4';

  @bindable private showSideRopes = true;
  @bindable private showLabels = true;

  attached() {
    if (!this.dimensions) return;
    this.drawNetSide(this.dimensions);
    this.drawNetTop(this.dimensions);
  }

  showSideRopesChanged() {
    if (this.showSideRopes && this.dimensions.Length !== this.dimensions.Width) {
      this.showSideRopes = false;
    }

    this.drawNetSide(this.dimensions);
    this.drawNetTop(this.dimensions);
  }

  showLabelsChanged() {
    this.drawNetSide(this.dimensions);
    this.drawNetTop(this.dimensions);
  }

  dimensionsChanged() {
    if (this.dimensions) {
      if (this.dimensions && !this.viewMode) {
        if (this.dimensions.NetShapeId == 9) {
          this.viewMode = 'top';
        } else {
          this.viewMode = 'side';
        }
      }

      this.drawNetSide(this.dimensions);
      this.drawNetTop(this.dimensions);
    }
  }

  private drawNetTop(dimensions) {
    if (!this.drawingTopEl) return;

    const c = <any>this.drawingTopEl;
    const ctx = c.getContext('2d');
    ctx.clearRect(0, 0, c.width, c.height);
    ctx.beginPath();

    const widthTotal = 400;

    if (dimensions.NetShapeId === 9) {
      this.drawNetTopSquare(ctx, dimensions, widthTotal);
    } else {
      this.drawNetTopRound(ctx, dimensions, widthTotal);
    }
  }

  private drawNetTopRound(ctx, dimensions, widthTotal) {
    if (dimensions.RadiusCornerToCenter && dimensions.SideCount) {
      // use 280 as diameter for the drawed circle - calculate multiplier based on this
      const multiplier = widthTotal / (dimensions.RadiusCornerToCenter * 2);

      const circleRadius = 190;
      const circleCenterX = 250;
      const circleCenterY = 205;

      const circleRadiusBottom =
        circleRadius *
        ((dimensions.RadiusCornerToCenterBottom || dimensions.RadiusCornerToCenter) / dimensions.RadiusCornerToCenter);

      let currentStartPositionX = undefined;
      let currentStartPositionY = undefined;

      const angle = (2 * Math.PI) / dimensions.SideCount;

      this.drawingTopEl['height'] = circleCenterY + circleRadius + 5;

      // start drawing lines from the center of the circle
      ctx.moveTo(circleCenterX, circleCenterY);

      // draw from top of net (water line)
      for (let i = 0; i <= dimensions.SideCount; i++) {
        const newPositionX = circleCenterX + circleRadius * Math.cos(i * angle + angle / 2);
        const newPositionY = circleCenterY + circleRadius * Math.sin(i * angle + angle / 2);

        if (currentStartPositionX && currentStartPositionY) {
          // draw line from previous endpoint to new endpoint
          ctx.strokeStyle = this.colorDefault;
          ctx.beginPath();

          ctx.moveTo(currentStartPositionX, currentStartPositionY);
          ctx.lineTo(newPositionX, newPositionY);
          ctx.stroke();
        }

        ctx.strokeStyle = this.colorFaded;
        ctx.beginPath();

        // move back to the center for the next line
        ctx.moveTo(circleCenterX, circleCenterY);

        // draw line from center to new endpoint
        ctx.lineTo(newPositionX, newPositionY);

        ctx.stroke();

        currentStartPositionX = newPositionX;
        currentStartPositionY = newPositionY;
      }

      ctx.strokeStyle = this.colorDefault;
      ctx.beginPath();

      currentStartPositionX = undefined;
      currentStartPositionY = undefined;

      // draw from top of net (bottom rope + cross ropes)
      for (let i = 0; i <= dimensions.SideCount; i++) {
        const newPositionX = circleCenterX + circleRadiusBottom * Math.cos(i * angle + angle / 2);
        const newPositionY = circleCenterY + circleRadiusBottom * Math.sin(i * angle + angle / 2);

        if (currentStartPositionX && currentStartPositionY) {
          // draw line from previous endpoint to new endpoint
          ctx.moveTo(currentStartPositionX, currentStartPositionY);
          ctx.lineTo(newPositionX, newPositionY);
        }

        currentStartPositionX = newPositionX;
        currentStartPositionY = newPositionY;
      }

      ctx.stroke();

      if (this.showSideRopes) {
        // draw liftingropes at bottom
        if (dimensions.LiftingRopes) {
          const angleLiftingRopes = (2 * Math.PI) / Math.max(dimensions.SideCount, dimensions.LiftingRopes);

          let numberOfSideRopesBetweenLiftingRopes = 0;
          let angleSideRopes = undefined;

          if (dimensions.LiftingRopes <= dimensions.SideRopes) {
            numberOfSideRopesBetweenLiftingRopes =
              Math.round((10 * (dimensions.SideRopes - dimensions.LiftingRopes)) / dimensions.LiftingRopes) / 10;

            if (numberOfSideRopesBetweenLiftingRopes >= 1) {
              angleSideRopes = angleLiftingRopes / (numberOfSideRopesBetweenLiftingRopes + 1);
            } else {
              angleSideRopes = angleLiftingRopes / 2;
            }
          }

          // draw from top of net (ropes)
          for (let i = 1; i <= dimensions.LiftingRopes; i++) {
            const newPositionX = circleCenterX + circleRadius * Math.cos(i * angleLiftingRopes + angle / 2);
            const newPositionY = circleCenterY + circleRadius * Math.sin(i * angleLiftingRopes + angle / 2);

            ctx.strokeStyle = this.colorLiftingRopes;
            ctx.beginPath();

            // move back to the center for the next line
            ctx.moveTo(circleCenterX, circleCenterY);

            // draw line from center to new endpoint
            ctx.lineTo(newPositionX, newPositionY);

            ctx.stroke();

            if (numberOfSideRopesBetweenLiftingRopes > 0) {
              // because the sideropes are a bit shorter, use a shorter radius (could calculate precisely, but
              // this will look fine)
              const reduceRadiusFactor = 0.98;

              ctx.strokeStyle = this.colorSideRopes;
              ctx.beginPath();

              if (numberOfSideRopesBetweenLiftingRopes >= 1) {
                for (let j = 1; j <= numberOfSideRopesBetweenLiftingRopes; j++) {
                  // draw a line from the bottom rope to the main rope - the line to the main rope is intentionally a bit
                  // too long, so it will also be visisble for cylinder nets where the bottom is the same size as the main rope
                  const newPositionX =
                    circleCenterX + circleRadius * Math.cos(i * angleLiftingRopes + angle / 2 + angleSideRopes * j);
                  const newPositionY =
                    circleCenterY + circleRadius * Math.sin(i * angleLiftingRopes + angle / 2 + angleSideRopes * j);

                  const newPositionXBottom =
                    circleCenterX +
                    circleRadiusBottom *
                      reduceRadiusFactor *
                      Math.cos(i * angleLiftingRopes + angle / 2 + angleSideRopes * j);
                  const newPositionYBottom =
                    circleCenterY +
                    circleRadiusBottom *
                      reduceRadiusFactor *
                      Math.sin(i * angleLiftingRopes + angle / 2 + angleSideRopes * j);

                  ctx.moveTo(newPositionXBottom, newPositionYBottom);
                  ctx.lineTo(newPositionX, newPositionY);
                }
              } else if (i % 2 == 0) {
                // draw a line from the bottom rope to the main rope - the line to the main rope is intentionally a bit
                // too long, so it will also be visisble for cylinder nets where the bottom is the same size as the main rope
                const newPositionX =
                  circleCenterX + circleRadius * Math.cos(i * angleLiftingRopes + angle / 2 + angleSideRopes);
                const newPositionY =
                  circleCenterY + circleRadius * Math.sin(i * angleLiftingRopes + angle / 2 + angleSideRopes);

                const newPositionXBottom =
                  circleCenterX +
                  circleRadiusBottom *
                    reduceRadiusFactor *
                    Math.cos(i * angleLiftingRopes + angle / 2 + angleSideRopes);
                const newPositionYBottom =
                  circleCenterY +
                  circleRadiusBottom *
                    reduceRadiusFactor *
                    Math.sin(i * angleLiftingRopes + angle / 2 + angleSideRopes);

                ctx.moveTo(newPositionXBottom, newPositionYBottom);
                ctx.lineTo(newPositionX, newPositionY);
              }

              ctx.stroke();
            }
          }
        }
      }

      this.drawingTopContainerEl.style.display = 'block';
    } else {
      this.drawingTopContainerEl.style.display = 'none';
    }
  }

  private drawNetTopSquare(ctx, dimensions, widthTotal) {
    // draw square based on length/width
    let isFakeSquare = false;
    const hasCutCorners = this.hasCutCorners();

    if (dimensions.SquareLength == null) {
      isFakeSquare = true;
      dimensions.Length = 25;
      dimensions.SquareLength = 25 - (hasCutCorners ? 4 : 0);
    }

    if (dimensions.SquareWidth == null) {
      isFakeSquare = true;
      dimensions.Width = 25;
      dimensions.SquareWidth = 25 - (hasCutCorners ? 4 : 0);
    }

    if (hasCutCorners && dimensions.SlopeCornerLength == null) {
      isFakeSquare = true;
      dimensions.SlopeCornerLength = 2;
    }

    if (dimensions.SquareLength && dimensions.SquareWidth) {
      const leftMargin = 20;
      const topMargin = 45;
      const multiplier = widthTotal / dimensions.Length;

      const leftSideSquare = leftMargin;
      const topSquare = topMargin;
      const rightSideSquare = leftMargin + (2 * dimensions.SlopeCornerLength + dimensions.SquareLength) * multiplier;
      const bottomSquare = topMargin + (2 * dimensions.SlopeCornerLength + dimensions.SquareWidth) * multiplier;

      this.drawingTopEl['height'] = bottomSquare + 5;

      if (isFakeSquare) {
        ctx.setLineDash([5, 3]);
      }

      // draw top square
      ctx.moveTo(leftMargin + dimensions.SlopeCornerLength * multiplier, topSquare);
      ctx.lineTo(leftMargin + (dimensions.SlopeCornerLength + dimensions.SquareLength) * multiplier, topSquare);
      ctx.lineTo(rightSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);
      ctx.lineTo(rightSideSquare, bottomSquare - dimensions.SlopeCornerLength * multiplier);
      ctx.lineTo(rightSideSquare - dimensions.SlopeCornerLength * multiplier, bottomSquare);
      ctx.lineTo(leftSideSquare + dimensions.SlopeCornerLength * multiplier, bottomSquare);
      ctx.lineTo(leftSideSquare, topSquare + (dimensions.SlopeCornerLength + dimensions.SquareWidth) * multiplier);
      ctx.lineTo(leftSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);
      ctx.lineTo(leftMargin + dimensions.SlopeCornerLength * multiplier, topSquare);

      // draw bottom square
      const bottomLeftMargin = leftMargin + ((dimensions.Length - dimensions.BottomLength) / 2) * multiplier;
      const bottomRightSideSquare = bottomLeftMargin + dimensions.BottomSquareLength * multiplier;
      const bottomTopSquare = topMargin + ((dimensions.Width - dimensions.BottomWidth) / 2) * multiplier;
      const bottomBottomSquare = bottomTopSquare + dimensions.BottomSquareWidth * multiplier;

      ctx.moveTo(bottomLeftMargin, bottomTopSquare);
      ctx.lineTo(bottomRightSideSquare, bottomTopSquare);
      ctx.lineTo(bottomRightSideSquare, bottomBottomSquare);
      ctx.lineTo(bottomLeftMargin, bottomBottomSquare);
      ctx.lineTo(bottomLeftMargin, bottomTopSquare);

      ctx.strokeStyle = 'black';

      ctx.stroke();

      // consider adding lines to better illustrate lines
      ctx.restore();

      // draw lines from corners to bottom
      ctx.strokeStyle = this.colorFaded;
      ctx.beginPath();

      ctx.moveTo(leftMargin + dimensions.SlopeCornerLength * multiplier, topSquare);
      ctx.lineTo(bottomLeftMargin, bottomTopSquare);
      ctx.moveTo(leftMargin + (dimensions.SlopeCornerLength + dimensions.SquareLength) * multiplier, topSquare);
      ctx.lineTo(bottomRightSideSquare, bottomTopSquare);
      ctx.moveTo(rightSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);
      ctx.lineTo(bottomRightSideSquare, bottomTopSquare);
      ctx.moveTo(rightSideSquare, bottomSquare - dimensions.SlopeCornerLength * multiplier);
      ctx.lineTo(bottomRightSideSquare, bottomBottomSquare);
      ctx.moveTo(rightSideSquare - dimensions.SlopeCornerLength * multiplier, bottomSquare);
      ctx.lineTo(bottomRightSideSquare, bottomBottomSquare);
      ctx.moveTo(leftSideSquare + dimensions.SlopeCornerLength * multiplier, bottomSquare);
      ctx.lineTo(bottomLeftMargin, bottomBottomSquare);
      ctx.moveTo(leftSideSquare, topSquare + (dimensions.SlopeCornerLength + dimensions.SquareWidth) * multiplier);
      ctx.lineTo(bottomLeftMargin, bottomBottomSquare);
      ctx.moveTo(leftSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);
      ctx.lineTo(bottomLeftMargin, bottomTopSquare);

      // draw lines to center of bottom from corners
      const bottomCenterY = (bottomTopSquare + bottomBottomSquare) / 2;
      const bottomCenterX = (bottomLeftMargin + bottomRightSideSquare) / 2;

      if (!this.hasCutCorners) {
        ctx.moveTo(bottomLeftMargin, bottomTopSquare);
        ctx.lineTo(bottomCenterX, bottomCenterY);
        ctx.moveTo(bottomRightSideSquare, bottomTopSquare);
        ctx.lineTo(bottomCenterX, bottomCenterY);
        ctx.moveTo(bottomRightSideSquare, bottomBottomSquare);
        ctx.lineTo(bottomCenterX, bottomCenterY);
        ctx.moveTo(bottomLeftMargin, bottomBottomSquare);
        ctx.lineTo(bottomCenterX, bottomCenterY);

        ctx.stroke();
      }

      // draw ropes if the net is square, if not the distribution of ropes cannot be done
      // in this module
      if (!this.hasCutCorners() && this.showSideRopes && dimensions.Length === dimensions.Width) {
        // draw corner ropes
        ctx.strokeStyle = this.colorLiftingRopes;
        ctx.beginPath();

        // draw ropes from corners at top to corners at bottom
        ctx.moveTo(leftMargin + dimensions.SlopeCornerLength * multiplier, topSquare);
        ctx.lineTo(bottomLeftMargin, bottomTopSquare);
        ctx.moveTo(leftMargin + (dimensions.SlopeCornerLength + dimensions.SquareLength) * multiplier, topSquare);
        ctx.lineTo(bottomRightSideSquare, bottomTopSquare);
        ctx.moveTo(rightSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);
        ctx.lineTo(bottomRightSideSquare, bottomTopSquare);
        ctx.moveTo(rightSideSquare, bottomSquare - dimensions.SlopeCornerLength * multiplier);
        ctx.lineTo(bottomRightSideSquare, bottomBottomSquare);
        ctx.moveTo(rightSideSquare - dimensions.SlopeCornerLength * multiplier, bottomSquare);
        ctx.lineTo(bottomRightSideSquare, bottomBottomSquare);
        ctx.moveTo(leftSideSquare + dimensions.SlopeCornerLength * multiplier, bottomSquare);
        ctx.lineTo(bottomLeftMargin, bottomBottomSquare);
        ctx.moveTo(leftSideSquare, topSquare + (dimensions.SlopeCornerLength + dimensions.SquareWidth) * multiplier);
        ctx.lineTo(bottomLeftMargin, bottomBottomSquare);
        ctx.moveTo(leftSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);
        ctx.lineTo(bottomLeftMargin, bottomTopSquare);

        // draw ropes from corner at bottom to center bottom
        ctx.moveTo(bottomLeftMargin, bottomTopSquare);
        ctx.lineTo(bottomCenterX, bottomCenterY);
        ctx.moveTo(bottomRightSideSquare, bottomTopSquare);
        ctx.lineTo(bottomCenterX, bottomCenterY);
        ctx.moveTo(bottomRightSideSquare, bottomBottomSquare);
        ctx.lineTo(bottomCenterX, bottomCenterY);
        ctx.moveTo(bottomLeftMargin, bottomBottomSquare);
        ctx.lineTo(bottomCenterX, bottomCenterY);

        // distribute the remaining ropes at each side
        const numberOfRopesToDistribute = dimensions.LiftingRopes - (dimensions.SlopeCornerLength > 0 ? 8 : 4);
        const percentageRopesAtShortSide = (2 * dimensions.Length) / (2 * dimensions.Length + 2 * dimensions.Width);

        const ropesAtShortSide = Math.ceil((numberOfRopesToDistribute * percentageRopesAtShortSide) / 2);
        const ropesAtLongSide = numberOfRopesToDistribute / 2 - ropesAtShortSide;

        const distanceRopeTopX = (dimensions.SquareLength * multiplier) / (ropesAtShortSide + 1);
        const distanceRopeTopY = (dimensions.SquareWidth * multiplier) / (ropesAtLongSide + 1);
        const distanceRopeBottomX = (dimensions.BottomSquareLength * multiplier) / (ropesAtShortSide + 1);
        const distanceRopeBottomY = (dimensions.BottomSquareWidth * multiplier) / (ropesAtLongSide + 1);

        //logger.debug('bottom ' + numberOfRopesToDistribute + ', ' + percentageRopesAtShortSide + ', ' + ropesAtShortSide + ', ' + ropesAtLongSide + ', ' + distanceRopeBottomX + ', ' + distanceRopeBottomY);

        for (let i = 1; i <= ropesAtShortSide; i++) {
          const currentXTop = leftMargin + dimensions.SlopeCornerLength * multiplier + distanceRopeTopX * i;
          const currentXBottom = bottomLeftMargin + distanceRopeBottomX * i;

          // from main rope to bottom rope
          ctx.moveTo(currentXTop, topSquare);
          ctx.lineTo(currentXBottom, bottomTopSquare);
          ctx.moveTo(currentXTop, bottomSquare);
          ctx.lineTo(currentXBottom, bottomBottomSquare);

          // from bottom rope to center bottom
          ctx.moveTo(currentXBottom, bottomTopSquare);
          ctx.lineTo(bottomCenterX, bottomCenterY);
          ctx.moveTo(currentXBottom, bottomBottomSquare);
          ctx.lineTo(bottomCenterX, bottomCenterY);
        }

        for (let i = 1; i <= ropesAtLongSide; i++) {
          const currentYTop = topSquare + dimensions.SlopeCornerLength * multiplier + distanceRopeTopY * i;
          const currentYBottom = bottomTopSquare + distanceRopeBottomY * i;

          // from main rope to bottom rope
          ctx.moveTo(leftMargin, currentYTop);
          ctx.lineTo(bottomLeftMargin, currentYBottom);
          ctx.moveTo(rightSideSquare, currentYTop);
          ctx.lineTo(bottomRightSideSquare, currentYBottom);

          // from bottom rope to center bottom
          ctx.moveTo(bottomLeftMargin, currentYBottom);
          ctx.lineTo(bottomCenterX, bottomCenterY);
          ctx.moveTo(bottomRightSideSquare, currentYBottom);
          ctx.lineTo(bottomCenterX, bottomCenterY);
        }

        ctx.stroke();
      }

      // draw labels/explainations
      if (this.showLabels) {
        ctx.strokeStyle = this.colorFaded;
        ctx.beginPath();

        ctx.moveTo(leftSideSquare, topSquare - 30);
        ctx.lineTo(rightSideSquare, topSquare - 30);

        ctx.moveTo(leftSideSquare, topSquare - 10);
        ctx.lineTo(rightSideSquare, topSquare - 10);

        ctx.moveTo(leftSideSquare, topSquare - 40);
        ctx.lineTo(leftSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);

        ctx.moveTo(leftSideSquare + dimensions.SlopeCornerLength * multiplier, topSquare - 20);
        ctx.lineTo(leftSideSquare + dimensions.SlopeCornerLength * multiplier, topSquare);

        ctx.moveTo(rightSideSquare, topSquare - 40);
        ctx.lineTo(rightSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);

        ctx.moveTo(rightSideSquare - dimensions.SlopeCornerLength * multiplier, topSquare - 20);
        ctx.lineTo(rightSideSquare - dimensions.SlopeCornerLength * multiplier, topSquare);

        ctx.moveTo(rightSideSquare + 35, topSquare);
        ctx.lineTo(rightSideSquare + 35, bottomSquare);

        ctx.moveTo(rightSideSquare + 10, topSquare);
        ctx.lineTo(rightSideSquare + 10, bottomSquare);

        ctx.moveTo(rightSideSquare - dimensions.SlopeCornerLength * multiplier, topSquare);
        ctx.lineTo(rightSideSquare + 45, topSquare);

        ctx.moveTo(rightSideSquare, topSquare + dimensions.SlopeCornerLength * multiplier);
        ctx.lineTo(rightSideSquare + 20, topSquare + dimensions.SlopeCornerLength * multiplier);

        ctx.moveTo(rightSideSquare - dimensions.SlopeCornerLength * multiplier, bottomSquare);
        ctx.lineTo(rightSideSquare + 45, bottomSquare);

        ctx.moveTo(rightSideSquare, bottomSquare - dimensions.SlopeCornerLength * multiplier);
        ctx.lineTo(rightSideSquare + 20, bottomSquare - dimensions.SlopeCornerLength * multiplier);

        ctx.font = '10px';

        ctx.fillText('D', leftMargin + (rightSideSquare - leftMargin) / 2, topSquare - 35);

        ctx.fillText('A', leftMargin + (rightSideSquare - leftMargin) / 2, topSquare - 15);

        if (dimensions.SlopeCornerLength && dimensions.SlopeCornerLength !== 0) {
          ctx.fillText('C', leftSideSquare - 5 + (dimensions.SlopeCornerLength * multiplier) / 2, topSquare - 15);

          ctx.fillText('C', rightSideSquare - 5 - (dimensions.SlopeCornerLength / 2) * multiplier, topSquare - 15);

          ctx.fillText('C', rightSideSquare + 15, topSquare + 5 + (dimensions.SlopeCornerLength * multiplier) / 2);

          ctx.fillText('C', rightSideSquare + 15, bottomSquare + 5 - (dimensions.SlopeCornerLength * multiplier) / 2);

          ctx.fillText(
            'B',
            leftSideSquare + 5 + (dimensions.SlopeCornerLength * multiplier) / 2,
            topSquare + 10 + (dimensions.SlopeCornerLength * multiplier) / 2
          );

          ctx.fillText(
            'B',
            rightSideSquare - 10 - (dimensions.SlopeCornerLength * multiplier) / 2,
            topSquare + 10 + (dimensions.SlopeCornerLength * multiplier) / 2
          );
        }

        ctx.fillText(
          'F',
          rightSideSquare + 40,
          topSquare + ((2 * dimensions.SlopeCornerLength + dimensions.SquareWidth) * multiplier) / 2
        );

        ctx.fillText(
          'E',
          rightSideSquare + 15,
          topSquare + ((2 * dimensions.SlopeCornerLength + dimensions.SquareWidth) * multiplier) / 2
        );
      }

      ctx.stroke();

      this.drawingTopContainerEl.style.display = 'block';
    } else {
      this.drawingTopContainerEl.style.display = 'none';
    }
  }

  private hasCutCorners() {
    return this.designType?.DesignCode == 'MNSQ-CC' || this.designType?.DesignCode == 'MNR-CC';
  }

  private drawNetSide(dimensions) {
    if (!this.drawingSideEl) return;

    // clear the existing drawing (if any)
    const c = <any>this.drawingSideEl;
    const ctx = c.getContext('2d');
    ctx.clearRect(0, 0, c.width, c.height);
    ctx.beginPath();

    if (dimensions?.NetShapeId === 9) {
      this.drawNetSideSquare(ctx, dimensions);
    } else if (dimensions.RadiusCornerToCenter && dimensions.DepthBottom) {
      this.drawNetSideRound(ctx, dimensions);
    } else {
      this.drawingSideContainerEl.style.display = 'none';
    }
  }

  private drawNetSideSquare(ctx, dimensions) {
    if (dimensions.Length && dimensions.DepthBottom) {
      const multiplier = 380 / dimensions.Length;

      let topNet = 10;
      let bottomNet: number = undefined;

      this.drawingSideEl['height'] = topNet + dimensions.TotalDepth * multiplier + 40;

      const lengthTop = dimensions.Length * multiplier;
      const lengthBottom = dimensions.BottomSquareLength ? dimensions.BottomSquareLength * multiplier : lengthTop;

      const offsetBottom = (lengthTop - lengthBottom) / 2;

      // draw jumpnet
      if (dimensions.HeightJumpNet) {
        ctx.strokeRect(10, 10, lengthTop, dimensions.HeightJumpNet * multiplier);
        topNet = 10 + dimensions.HeightJumpNet * multiplier;
      }

      // draw net to bottom rope
      ctx.moveTo(10, topNet);
      ctx.lineTo(10 + lengthTop, topNet);

      bottomNet = topNet + dimensions.DepthBottom * multiplier;
      ctx.lineTo(10 + offsetBottom + lengthBottom, bottomNet);
      ctx.lineTo(10 + offsetBottom, bottomNet);
      ctx.lineTo(10, topNet);

      // move to bottom left of square
      ctx.moveTo(10 + offsetBottom, bottomNet);

      // draw inner bottom
      if (dimensions.DepthBottomCenter) {
        ctx.lineTo(10 + offsetBottom + lengthBottom / 2, bottomNet + dimensions.DepthBottomCenter * multiplier);
        ctx.lineTo(10 + offsetBottom + lengthBottom, bottomNet);

        // move to bottom left of square
        ctx.moveTo(10 + offsetBottom, bottomNet);
      }

      ctx.stroke();

      // draw cut corners
      ctx.setLineDash([5, 3]);

      ctx.moveTo(10 + dimensions.SlopeCornerLength * multiplier, topNet - dimensions.HeightJumpNet * multiplier);
      ctx.lineTo(10 + offsetBottom, bottomNet);

      ctx.moveTo(
        10 + lengthTop - dimensions.SlopeCornerLength * multiplier,
        topNet - dimensions.HeightJumpNet * multiplier
      );
      ctx.lineTo(10 + offsetBottom + lengthBottom, bottomNet);

      ctx.stroke();

      ctx.restore();

      if (this.showLabels) {
        // add texts
        ctx.font = '10px';
        if (dimensions.HeightJumpNet) {
          ctx.fillText('H1', lengthTop + 20, 10 + dimensions.HeightJumpNet * multiplier);
        }

        ctx.fillText('H2', lengthTop + 20, 20 + (dimensions.DepthBottom / 2) * multiplier);

        if (dimensions.DepthBottomCenter) {
          ctx.fillText('H3', lengthTop + 20, 10 + bottomNet + (dimensions.DepthBottomCenter / 2) * multiplier);
        }

        ctx.stroke();
      }

      this.drawingSideContainerEl.style.display = 'block';
    } else {
      this.drawingSideContainerEl.style.display = 'none';
    }
  }

  private drawNetSideRound(ctx, dimensions) {
    const multiplier = 380 / (dimensions.RadiusCornerToCenter * 2);

    let topNet = 10;
    let bottomNet: number = undefined;

    this.drawingSideEl['height'] = topNet + dimensions.TotalDepth * multiplier + 20;

    const diameterTop = dimensions.RadiusCornerToCenter * 2 * multiplier;
    const diameterBottom = dimensions.RadiusCornerToCenterBottom
      ? dimensions.RadiusCornerToCenterBottom * 2 * multiplier
      : diameterTop;

    const offsetBottom = (diameterTop - diameterBottom) / 2;

    // draw jumpnet
    if (dimensions.HeightJumpNet) {
      ctx.strokeRect(10, 10, diameterTop, dimensions.HeightJumpNet * multiplier);
      topNet = 10 + dimensions.HeightJumpNet * multiplier;
    }

    // draw net to bottom rope
    ctx.moveTo(10, topNet);
    ctx.lineTo(10 + diameterTop, topNet);

    bottomNet = topNet + dimensions.DepthBottom * multiplier;

    if (this.designType?.DesignCode === 'MNCL-C') {
      // MNCL-C is curved only at bottom, so create a "breakpoint" 3m above the bottom rope
      ctx.lineTo(10 + diameterTop, bottomNet - 3 * multiplier);
      ctx.lineTo(10 + offsetBottom + diameterBottom, bottomNet);
      ctx.lineTo(10 + offsetBottom, bottomNet);
      ctx.lineTo(10, bottomNet - 3 * multiplier);
      ctx.lineTo(10, topNet);
    } else {
      ctx.lineTo(10 + offsetBottom + diameterBottom, bottomNet);
      ctx.lineTo(10 + offsetBottom, bottomNet);
      ctx.lineTo(10, topNet);
    }

    // draw second main rope if combinet
    if (dimensions.IsCombiNet) {
      if (dimensions?.HeightJumpNetSmallFish) {
        const secondJumpNet =
          topNet + (dimensions.DepthSecondMainRope - dimensions.HeightJumpNetSmallFish) * multiplier;
        ctx.moveTo(10, secondJumpNet);
        ctx.lineTo(10 + diameterTop, secondJumpNet);
      }
      const secondMainRope = topNet + dimensions.DepthSecondMainRope * multiplier;

      ctx.moveTo(10, secondMainRope);
      ctx.lineTo(10 + diameterTop, secondMainRope);
    }

    // move to bottom left of square
    ctx.moveTo(10 + offsetBottom, bottomNet);

    const centerInnerBottom = 10 + offsetBottom + diameterBottom / 2;
    const centerInnerBottomDepth = bottomNet + dimensions.DepthBottomCenter * multiplier;

    // draw inner bottom
    if (dimensions.DepthBottomCenter) {
      ctx.lineTo(centerInnerBottom, centerInnerBottomDepth);
      ctx.lineTo(10 + offsetBottom + diameterBottom, bottomNet);

      // move to bottom left of square
      ctx.moveTo(10 + offsetBottom, bottomNet);
    }

    // draw outer bottom
    if (dimensions.DepthBottomCenterExtraBottom) {
      ctx.lineTo(
        10 + offsetBottom + diameterBottom / 2,
        bottomNet + dimensions.DepthBottomCenterExtraBottom * multiplier
      );
      ctx.lineTo(10 + offsetBottom + diameterBottom, bottomNet);

      // move to bottom left of square
      ctx.moveTo(10 + offsetBottom, bottomNet);
    }

    ctx.stroke();

    if (this.showLabels) {
      // add texts
      ctx.font = '10px';
      if (dimensions.HeightJumpNet) {
        ctx.fillText('H1', diameterTop + 20, 10 + dimensions.HeightJumpNet * multiplier);
      }

      ctx.fillText('H2', diameterTop + 20, 20 + (dimensions.DepthBottom / 2) * multiplier);

      if (dimensions.DepthBottomCenter) {
        ctx.fillText('H3', diameterTop + 20, 10 + bottomNet + (dimensions.DepthBottomCenter / 2) * multiplier);
      }

      if (dimensions.DepthBottomCenterExtraBottom) {
        ctx.fillText(
          'H4',
          diameterTop + 20,
          10 + bottomNet + (dimensions.DepthBottomCenterExtraBottom / 2) * multiplier
        );
      }

      ctx.stroke();
    }

    if (this.showSideRopes) {
      // draw ropes
      const liftingRopesVisibleSide = dimensions.LiftingRopes / 2;

      // to because the net is a cylinder, we need to calculate the distance between the ropes
      // when viewed from the side by using the "triangles" created in the net. Probably a more
      // elegant way to do this, but this does the trick.. draw line to each side, starting from
      // the center

      const ropesEachWay = liftingRopesVisibleSide / 2;
      const centerNet = 10 + diameterTop / 2;

      const angle = (2 * Math.PI) / Math.max(dimensions.SideCount, dimensions.LiftingRopes);

      let currentDistanceFromCenterTop = undefined;
      let currentDistanceFromCenterBottom = undefined;

      let numberOfSideRopesBetweenLiftingRopes = 0;
      let angleSideRopes = undefined;

      if (dimensions.LiftingRopes <= dimensions.SideRopes) {
        numberOfSideRopesBetweenLiftingRopes =
          Math.round(((dimensions.SideRopes - dimensions.LiftingRopes) * 10) / dimensions.LiftingRopes) / 10;

        if (numberOfSideRopesBetweenLiftingRopes >= 1) {
          angleSideRopes = angle / (numberOfSideRopesBetweenLiftingRopes + 1);
        } else {
          angleSideRopes = angle / 2;
        }
      }

      for (let i = 0; i < ropesEachWay; i++) {
        currentDistanceFromCenterTop = (diameterTop / 2) * Math.cos(i * angle + angle / 2);
        currentDistanceFromCenterBottom = (diameterBottom / 2) * Math.cos(i * angle + angle / 2);

        ctx.strokeStyle = this.colorLiftingRopes;
        ctx.beginPath();

        ctx.moveTo(centerNet - currentDistanceFromCenterTop, topNet);
        ctx.lineTo(centerNet - currentDistanceFromCenterBottom, bottomNet);
        ctx.lineTo(centerInnerBottom, centerInnerBottomDepth);

        ctx.moveTo(centerNet + currentDistanceFromCenterTop, topNet);
        ctx.lineTo(centerNet + currentDistanceFromCenterBottom, bottomNet);
        ctx.lineTo(centerInnerBottom, centerInnerBottomDepth);

        ctx.stroke();

        if (numberOfSideRopesBetweenLiftingRopes > 0) {
          ctx.strokeStyle = this.colorSideRopes;
          ctx.beginPath();

          if (numberOfSideRopesBetweenLiftingRopes >= 1) {
            for (let j = 1; j <= numberOfSideRopesBetweenLiftingRopes; j++) {
              const sideRopeFromCenterTop = (diameterTop / 2) * Math.cos(i * angle + angle / 2 + angleSideRopes * j);
              const sideRopeFromCenterBottom =
                (diameterBottom / 2) * Math.cos(i * angle + angle / 2 + angleSideRopes * j);

              ctx.moveTo(centerNet - sideRopeFromCenterTop, topNet);
              ctx.lineTo(centerNet - sideRopeFromCenterBottom, bottomNet);

              ctx.moveTo(centerNet + sideRopeFromCenterTop, topNet);
              ctx.lineTo(centerNet + sideRopeFromCenterBottom, bottomNet);
            }
          } else {
            if (i % 2 == 0) {
              const sideRopeFromCenterTop = (diameterTop / 2) * Math.cos(i * angle + angle / 2 + angleSideRopes);
              const sideRopeFromCenterBottom = (diameterBottom / 2) * Math.cos(i * angle + angle / 2 + angleSideRopes);

              ctx.moveTo(centerNet - sideRopeFromCenterTop, topNet);
              ctx.lineTo(centerNet - sideRopeFromCenterBottom, bottomNet);

              ctx.moveTo(centerNet + sideRopeFromCenterTop, topNet);
              ctx.lineTo(centerNet + sideRopeFromCenterBottom, bottomNet);
            }
          }

          ctx.stroke();

          ctx.strokeStyle = this.colorLiftingRopes;
        }
      }
    }

    this.drawingSideContainerEl.style.display = 'block';
  }
}
